/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/users/stocksnap-894430/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2619617"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Outsider님이 ", React.createElement(_components.a, {
    href: "https://retrotech.outsider.dev/"
  }, "레트로 테크"), " 만든 썰"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "AWS 대신 ", React.createElement(_components.a, {
    href: "https://aws.amazon.com/legal/amcskr/"
  }, "AMCS Korea")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "한국에서 제공되는 특정 AWS 커뮤니케이션 관련 서비스( AWS Cloud WAN, AWS Ground Station , Amazon Connect, Amazon Chime 및 Amazon Chime SDK의 PSTN 기능)는 AMCS Korea에서 판매하고 인보이스를 발행할 예정입니다."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://efamily.scourt.go.kr/pt/PtFrrpApplrInfoInqW.do?menuFg=02"
  }, "가족관계증명서"), " 데스크톱에서 발급 쉬워짐"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "간편인증 덕분"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "집에만 오면 현대카드 앱이 안 켜져요?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1.1.1.1/"
  }, "1.1.1.1")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.google.com/speed/public-dns"
  }, "8.8.8.8")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hyundaicard.com/index.jsp"
  }, "현대카드 웹사이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/lecture/14974733"
  }, "클리앙 제보 글")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/Nslookup"
  }, "nslookup")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "구글 이대로 괜찮은가?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.donga.com/news/It/article/all/20230806/120589088/1"
  }, "구글 지진 알람 논란")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.co.kr/maps/"
  }, "구글 맵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/travel"
  }, "구글 트래블")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://travel.google/"
  }, "travle.google")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wsj.com/articles/sergey-brin-google-ai-gemini-1b5aa41e"
  }, "세르게이 브린 재합류")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
